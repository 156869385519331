<template>
  <CytomineModal
    :active="active"
    :title="$t('algorithm-launch')"
    @close="$emit('update:active', false)"
  >
    <BLoading :is-full-page="false" :active="loading" class="small" />
    <template v-if="!loading">
      <AssayParameters
        :assay-params="assayParams"
        :is-valid.sync="paramsValid"
        :id-project="idProject"
        @valuesChanged="(values) => (paramsOutput = values)"
        @isValid="(value) => (paramsValid = value)"
      />
    </template>
    <template #footer>
      <span
        v-if="!paramsValid"
        class="mt-1 mr-4 red"
        style="font-size: 0.8rem; display: inline-block"
      >
        Please fix validation errors first!
      </span>
      <IdxBtn
        type="submit"
        color="primary"
        :disabled="!paramsValid"
        @click="validateAndLaunchAssay"
      >
        {{ $t('algorithm-launch') }}
      </IdxBtn>
      <IdxBtn @click="$emit('update:active', false)">
        {{ $t('close') }}
      </IdxBtn>
    </template>
  </CytomineModal>
</template>
<script>
import noteApi from '../../services/noteApi.js';
import { CytomineSlider } from '../form/index.js';
import AssayParameters from '../utils/AssayParameters.vue';
import CytomineModal from '@/components/utils/CytomineModal.vue';

const EXCLUDED_PARAMETER_NAMES = ['slide_path', 'project_id', 'image_id'];

export default {
  name: 'LaunchJobModal',
  components: {
    AssayParameters,
    CytomineModal,
    CytomineSlider,
  },
  props: {
    active: Boolean,
    idProject: {
      type: [String, Number],
      required: true,
    },
    assayParams: {
      type: Array,
      default: () => [],
    },
    algorithmSelected: {
      type: [String, Number],
      default: () => null,
    },
    selectedImgIds: {
      type: Array,
      default: () => [],
    },
    revision: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      loading: true,
      adaptedAssayParams: [],
      paramsValid: false,
      paramsOutput: null,
    };
  },
  computed: {
    thisAssayParams() {
      return this.assayParams;
    },
  },
  async created() {
    this.loading = false;
  },
  methods: {
    async validateAndLaunchAssay() {
      console.log(this.paramsOutput);

      if (!this.paramsValid) return;

      try {
        this.loading = true;
        const requestBody = {
          params: JSON.stringify(this.paramsOutput),
          projectId: this.idProject,
          selectedImgIds: this.selectedImgIds,
        };

        await noteApi.post(
          `napi/algorithm/${this.algorithmSelected}/parameter`,
          {
            data: requestBody,
          }
        );
        this.loading = false;
        this.$notify({
          type: 'success',
          text: this.$t('algorithm-run-start'),
        });

        this.$emit('update:revision', this.revision + 1);
        this.$emit('update:active', false);
      } catch (error) {
        this.loading = false;
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('algorithm-run-error'),
        });
      }
    },
  },
};
</script>

<style scoped>
h4 {
  font-weight: bold;
}

>>> .animation-content {
  max-width: 60% !important;
  width: 60%;
}

>>> .modal-card {
  width: 100%;
  height: 80vh;
}

.image-overview {
  max-height: 4rem;
  max-width: 10rem;
}
.red {
  color: red;
}
</style>
<style>
label .label {
  margin-bottom: 0px;
}
label .label:not(:last-child) {
  margin-bottom: 0px;
}
</style>
