<template>
  <div>
    <div class="flex flex-row-reverse">
      <a
        href="https://revealbio.atlassian.net/servicedesk/customer/portals"
        target="_blank"
        class="mx-5 my-3"
      >
        {{ $t('imagedx-support') }}
      </a>
    </div>
    <div class="panel w-full max-w-640 mx-auto mt-6">
      <div class="panel-heading flex justify-between align-center">
        <h1>
          <i class="fas fa-user" aria-hidden="true" />
          <template v-if="forgotUsername">
            {{ $t('forgot-username') }}
          </template>
          <template v-else-if="forgotPassword">
            {{ $t('forgot-password') }}
          </template>
          <template v-else>
            {{ $t('login') }}
          </template>
        </h1>
        <img src="@/assets/logo.svg" alt="imageDx" />
      </div>

      <div class="mb-0 p-4">
        <form v-if="forgotUsername" @submit.prevent="sendUsername">
          <IdxInput
            v-model="email"
            :label="$t('email')"
            name="email"
            type="email"
            class="mb-3"
          />

          <div class="flex gap-8 justify-end">
            <IdxBtn @click="forgotUsername = false">
              {{ $t('cancel') }}
            </IdxBtn>
            <IdxBtn type="submit" color="blue">
              {{ $t('get-username') }}
            </IdxBtn>
          </div>
        </form>

        <form v-else-if="forgotPassword" @submit.prevent="resetPassword">
          <IdxInput
            v-model="username"
            :label="$t('username')"
            name="username"
            class="mb-3"
          />

          <div class="flex gap-8 justify-end">
            <IdxBtn @click="forgotPassword = false">
              {{ $t('cancel') }}
            </IdxBtn>
            <IdxBtn type="submit" color="blue">
              {{ $t('reset-password') }}
            </IdxBtn>
          </div>
        </form>

        <!-- <register v-else-if="registering" @close="registering = false" /> -->

        <form v-else @submit.prevent="login">
          <IdxInput
            v-model="username"
            :label="$t('username')"
            name="username"
            maxlength="80"
            class="mb-3"
          />

          <IdxInput
            v-model="password"
            :label="$t('password')"
            name="password"
            type="password"
            class="mb-3"
          />

          <div class="flex align-center justify-between mb-2">
            <BField class="m-0">
              <BCheckbox v-model="rememberMe" name="remember-me" type="is-info">
                {{ $t('remember-me') }}
              </BCheckbox>
            </BField>

            <IdxBtn type="submit" color="blue">
              {{ $t('login') }}
            </IdxBtn>
          </div>
          <i18n path="forgot-credentials" tag="p" class="size-12 text-center">
            <template #username>
              <IdxBtn plain class="color-blue" @click="forgotUsername = true">
                {{ $t('username') }}
              </IdxBtn>
            </template>
            <template #password>
              <IdxBtn plain class="color-blue" @click="forgotPassword = true">
                {{ $t('password') }}
              </IdxBtn>
            </template>
          </i18n>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { Cytomine } from 'cytomine-client';
import { changeLanguageMixin } from '@/lang.js';

const generateComputedQueryParam = (param) => {
  return {
    get() {
      return this.$route.query[param] === 'true';
    },
    /** @param {boolean} bool */
    set(bool) {
      const query = { ...this.$route.query };
      if (bool) {
        query[param] = 'true';
      } else {
        delete query[param];
      }
      this.$router.push({ query });
    },
  };
};

export default {
  name: 'Login',
  mixins: [changeLanguageMixin],
  data() {
    return {
      username: '',
      password: '',
      email: '',
      rememberMe: true,
    };
  },
  computed: {
    /** @returns {CytoUser} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    forgotUsername: generateComputedQueryParam('reset-username'),
    forgotPassword: generateComputedQueryParam('reset-password'),
  },
  methods: {
    async login() {
      try {
        await this.$store.dispatch('currentUser/login', {
          username: this.username,
          password: this.password,
          rememberMe: this.rememberMe,
        });
        if (this.currentUser) {
          this.changeLanguage(this.currentUser.language);
          this.$notify({
            type: 'success',
            text: this.$t('notif-success-login'),
          });
        } else {
          this.$notify({
            type: 'error',
            text: this.$t('unexpected-error-info-message'),
          });
        }
      } catch (error) {
        console.log(error);
        let text = error.response.data.message;

        if (this.username.includes('@')) {
          text = this.$t('username-input-error');
        }

        if (text === 'Password expired') {
          text = this.$t('notif-error-password-expired', {
            resetLink: `<a href="${window.location.origin}/#/?reset-password=true">here</a>`,
          });
        }

        this.$notify({
          type: 'error',
          text: text,
          duration: -1,
        });
      }
    },
    async sendUsername() {
      try {
        await Cytomine.instance.forgotUsername(this.email);
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-forgot-username'),
          duration: 10000,
        });
        this.forgotUsername = false;
      } catch (error) {
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-forgot-username'),
        });
      }
    },
    async resetPassword() {
      try {
        await Cytomine.instance.forgotPassword(this.username);
        this.$notify({
          type: 'success',
          text: this.$t('notif-success-forgot-password'),
          duration: 10000,
        });
        this.forgotPassword = false;
      } catch (error) {
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-forgot-password'),
        });
      }
    },
  },
};
</script>
