export default {
  namespaced: true,
  state() {
    return {
      useCustomRectangleSize: false,
      rectangleSize: {
        width: 0,
        height: 0,
      },
    };
  },

  mutations: {
    setRectangleSize(state, value) {
      state.rectangleSize = value;
    },

    setUseCustomRectangleSize(state, value) {
      state.useCustomRectangleSize = value;
    },
  },
};
