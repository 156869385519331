<!--
  This component is used to display assay parameters based on a JSON file.
  Inputs are rendered using the following structure:
  [row[column[inputList[input]]]

  'adaptedAssayParams' is responsible for most of the work here. It takes the JSON input 
  and converts it to HTML inputs.
-->

<template>
  <div>
    <div
      v-if="jsonValidationNotes.length > 0"
      class="p-4"
      style="background-color:#ffdcdc;"
    >
      <h4 style="margin-bottom: 0.4em">
        <i class="fas fas-alert" /> Assay JSON Issues
      </h4>
      <p style="margin-bottom: 0.2em">
        The following issues were found in the assay JSON file:
      </p>
      <ul style="list-style: outside square;" class="mt-3">
        <li v-for="note in jsonValidationNotes" :key="note" class="red ml-5">
          {{ note.message }}
        </li>
      </ul>
    </div>
    <template v-for="param in adaptedAssayParams.filter((a) => a.visible)">
      <div :key="param.name" class="mt-5">
        <h4 style="margin-bottom: 0.4em">
          {{ param.name.toUpperCase() }}
        </h4>
        <p style="margin-bottom: 0.2em" v-html="param.description" />
        <BSelect
          v-if="param.selectableParameterTypes.length > 1"
          v-model="param.selectedDisplayType"
          class="mt-3 ml-1"
          @input="($event) => handleParameterTypeChanged($event, param)"
        >
          <option
            v-for="type in param.selectableParameterTypes"
            :key="type.displayType"
            :value="type.displayType"
          >
            {{ type.displayType }}
          </option>
        </BSelect>

        <div v-for="(inputRow, rowIndex) in param.inputs" :key="rowIndex">
          <div style="display: flex">
            <div
              v-for="(inputColumn, columnIndex) in inputRow"
              :key="rowIndex + '-' + columnIndex"
              style="flex: 1; padding: 4px"
            >
              <div
                v-for="(input, inputIndex) in inputColumn"
                :key="rowIndex + '-' + columnIndex + '-' + inputIndex"
                style="display: flex; flex-wrap: wrap; position: relative"
              >
                <!-- Default string or number input -->
                <BField
                  v-if="!input.optionGroups && input.type !== 'range'"
                  label=""
                  style="flex: 1"
                  class="mb-1"
                >
                  <IdxInput
                    v-model="input.value"
                    :type="input.inputType"
                    :step="input.type === 'float' ? 0.00001 : 1"
                    :placeholder="$t(input.type)"
                    label=""
                    :name="param.name"
                    @change="inputChanged(param)"
                  />
                </BField>
                <!-- A bool or enum field -->
                <BField
                  v-if="
                    input.optionGroups &&
                      (param.selectedDisplayType.includes('bool') ||
                        (param.selectedDisplayType.includes('enum') &&
                          !param.selectedDisplayType.includes('/') &&
                          !param.selectedDisplayType.includes(',')))
                  "
                  label=""
                  style="flex: 1"
                  class="mb-1"
                >
                  <BSelect
                    v-model="input.value"
                    :placeholder="$t('click-for-options')"
                    @input="inputChanged(param)"
                  >
                    <optgroup
                      v-for="optionGroup in input.optionGroups"
                      :key="optionGroup.name"
                      :label="optionGroup.name"
                    >
                      <option
                        v-for="option in optionGroup.options"
                        :key="option"
                        :value="option"
                      >
                        {{ option }}
                      </option>
                    </optgroup>
                  </BSelect>
                </BField>
                <!-- An enum field that allows custom strings -->
                <BField
                  v-if="
                    input.optionGroups &&
                      param.selectedDisplayType.includes('enum') &&
                      (param.selectedDisplayType.includes('/') ||
                        param.selectedDisplayType.includes(','))
                  "
                  label=""
                  style="flex: 1"
                  class="mb-1"
                >
                  <BAutocomplete
                    v-model="input.value"
                    :placeholder="$t('click-for-options')"
                    group-field="name"
                    group-options="options"
                    open-on-focus
                    :data="input.optionGroups"
                    @change="validateParam(param)"
                  />
                </BField>
                <!-- Range input -->
                <div v-if="input.type === 'range'" style="flex: 1">
                  <BField label="" style="max-width: 250px" class="mb-1">
                    <IdxInput
                      :key="input.type + '-1-' + inputIndex"
                      v-model="input.min"
                      type="Number"
                      :placeholder="$t('min')"
                      label=""
                      :name="param.name + '-1-'"
                      :step="1"
                      @change="
                        (event) => updateRangeField(param, 'min', event, input)
                      "
                    />
                  </BField>
                  <BField label="" style="max-width: 250px" class="mb-1">
                    <IdxInput
                      :key="input.type + '-2-' + inputIndex"
                      v-model="input.max"
                      type="Number"
                      :placeholder="$t('max')"
                      label=""
                      :name="param.name + '-2-'"
                      :step="1"
                      @change="
                        (event) => updateRangeField(param, 'max', event, input)
                      "
                    />
                  </BField>
                  <BField label="" style="max-width: 250px" class="mb-1">
                    <IdxInput
                      :key="input.type + '-3-' + inputIndex"
                      v-model="input.step"
                      type="Number"
                      :placeholder="$t('step') + ' (' + $t('optional') + ')'"
                      label=""
                      :name="param.name + '-3-'"
                      :step="1"
                      @change="
                        (event) => updateRangeField(param, 'step', event, input)
                      "
                    />
                  </BField>
                </div>
                <!-- Buttons for adding rows & columns -->
                <IdxBtn
                  v-if="
                    inputColumn.length > 1 ||
                      (input.allowMultipleHorizontal && inputRow.length > 1)
                  "
                  :link="true"
                  style="position: absolute; top: -2px; right: -11px"
                  @click="removeRow(param, rowIndex, columnIndex, inputIndex)"
                >
                  <BIcon
                    pack="fas"
                    icon="times-circle"
                    style="font-size: 15px; color: red"
                  />
                </IdxBtn>
                <div
                  v-if="
                    input.allowMultipleHorizontal &&
                      columnIndex === inputRow.length - 1 &&
                      inputIndex === 0
                  "
                  style="
                    position: absolute;
                    top: 20px;
                    right: -11px;
                    z-index: 99999;
                  "
                >
                  <IdxBtn :link="true" @click="addColumn(param, rowIndex)">
                    <BIcon
                      pack="fas"
                      icon="plus-circle"
                      style="font-size: 15px; color: green"
                    />
                  </IdxBtn>
                </div>
                <div
                  v-if="
                    input.allowMultiple &&
                      inputRow.length > 1 &&
                      inputIndex === inputColumn.length - 1
                  "
                  style="flex-basis: 100%"
                >
                  <IdxBtn
                    :link="true"
                    @click="addRow(param, rowIndex, columnIndex)"
                  >
                    <BIcon
                      pack="fas"
                      icon="plus-circle"
                      style="font-size: 12px; color: green"
                    />
                    <span style="color: green; font-size: 0.8rem">
                      {{ $t('add-input') }}
                    </span>
                  </IdxBtn>
                </div>
              </div>
            </div>
            <IdxBtn
              v-if="param.inputs.length > 1"
              :link="true"
              style="flex: 0.2; width: 2em"
              @click="removeRow(param, rowIndex)"
            >
              <BIcon
                pack="fas"
                icon="minus-circle"
                style="font-size: 12px; color: red"
              />
            </IdxBtn>
          </div>
        </div>
        <div v-if="param.allowMultiple" style="flex-basis: 100%">
          <IdxBtn :link="true" @click="addRow(param)">
            <BIcon
              pack="fas"
              icon="plus-circle"
              style="font-size: 12px; color: green"
            />
            <span style="color: green; font-size: 0.8rem">
              {{ $t('add-row') }}
            </span>
          </IdxBtn>
        </div>
        <div class="mt-0">
          <ul style="list-style: square outside; margin-left: 1.2em">
            <template v-for="validator in param.validators">
              <li
                v-if="
                  validator.type !== 'invalid-type' ||
                    (validator.type === 'invalid-type' &&
                      validator.displayError)
                "
                :key="validator.type"
                :class="validator.displayError ? 'red' : 'grey'"
                style="font-size: 11px"
              >
                {{ validator.message }}
              </li>
            </template>
          </ul>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
const EXCLUDED_PARAMETER_NAMES = ['slide_path', 'project_id', 'image_id'];
const HasValue = (value) => {
  return value !== undefined && value !== null && value !== '';
};

export default {
  name: 'LaunchJobModal',
  components: {},
  props: {
    active: Boolean,
    idProject: {
      type: [String, Number],
      required: true,
    },
    assayParams: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['valuesChanged', 'isValid'],
  data() {
    return {
      adaptedAssayParams: [],
      jsonValidationNotes: [],
      isValid: false,
    };
  },
  computed: {
    thisAssayParams() {
      return this.assayParams;
    },
  },
  watch: {
    active(newValue, oldValue) {
      if (newValue) {
        this.updateAdaptedParams();
      }
    },
    assayParams() {
      this.updateAdaptedParams();
    },
  },
  async created() {
    this.updateAdaptedParams();

    // do initial validation on all params
    let valid = true;
    for (const param of this.adaptedAssayParams) {
      const isValid = this.validateParam(param);
      if (!isValid) {
        valid = false;
      }
    }
    this.isValid = valid;

    // update the parent component
    this.formattedParams = this.convertToExportFormat();
    this.$emit('update:isValid', this.isValid);
    this.$emit('valuesChanged', this.formattedParams);
  },
  methods: {
    updateAdaptedParams() {
      this.adaptedAssayParams = this.assayParams
        .filter((param) => !EXCLUDED_PARAMETER_NAMES.includes(param.name))
        .map((param) => this.getAdaptedParameter(param));
    },
    isSimpleType(type) {
      return [
        'string',
        'str',
        'int',
        'float',
        'enum',
        'bool',
        'null',
        'path',
        'range',
      ].includes(type);
    },
    // parameter types that allow the user to add additional items
    allowMultiple(types) {
      return ['variable_tuple', 'list', 'dict'].some((a) => types.includes(a));
    },
    // converts a parameter type to HTML input type
    getInputType(paramType) {
      switch (paramType) {
        case 'string':
        case 'str':
        case 'enum':
        case 'null':
        case 'path':
        case 'bool':
        case 'ndarray':
          return 'String';
        case 'float':
        case 'int':
          return 'Number';
        case 'range':
          return 'Range';
        default:
          return 'String';
      }
    },
    // Recursively converts an Assay JSON param into a more readable JSON structure
    // See https://docs.google.com/document/d/1m1DechE6l8qW0pP5yk6IXypi3pS7UCFqEPOBGfJsjKo for Assay JSON format
    getAdaptedParameter(param) {
      const adaptedParam = {
        name: param.name,
        visible: param.visibility !== 'hide',
        types: [],
        description: param.description,
        inputs: [[]],
        allowMultiple: false,
        required: param.required,
        defaultValue: param.default?.value || null,
        validators:
          (param.validators &&
            param.validators.map((validator) => ({
              ...validator,
              message: this.generateValidationMessage(validator),
              displayError: false,
            }))) ||
          [],
      };

      // create hidden validator for invalid input type
      adaptedParam.validators.unshift({
        type: 'invalid-type',
        message: this.generateValidationMessage({ type: 'invalid-type' }),
        displayError: false,
      });

      // create a required validator if param is required
      if (param.required) {
        adaptedParam.validators.unshift({
          type: 'required',
          message: this.generateValidationMessage({ type: 'required' }),
          displayError: false,
        });
      }

      const getTypesRecursively = (typeObj, parent) => {
        try {
          if (typeObj.type === 'enum') {
            let enumType = parent.types.find((a) => a && a.type === 'enum');
            if (!enumType) {
              enumType = {
                type: 'enum',
                types: null,
                optionGroups: [],
              };
              parent.types.push(enumType);
            }

            enumType.optionGroups.push({
              name: typeObj.type,
              module: typeObj.module,
              options: typeObj.options,
              class: typeObj.class,
            });
          } else if (typeObj.category === 'ndarray') {
            parent.types.push({
              type: typeObj.category,
              types: null,
              shape: parent.value.type.shape, // only for ndarrays - defines the default inputs
            });
          } else if (typeof typeObj.type === 'string') {
            // simple type (int, bool, string, etc)
            parent.types.push({
              type: typeObj.type,
              types: null,
            });
          } else if (typeof typeObj.type === 'object') {
            if (typeObj.category === 'single') {
              // keep parent the same
              for (const type of typeObj.type) {
                getTypesRecursively(type, parent);
              }
            } else {
              const newType = {
                type: typeObj.category,
                types: [],
              };
              for (const type of typeObj.type) {
                getTypesRecursively(type, newType);
              }
              parent.types.push(newType);
            }
          }
        } catch (ex) {
          this.addJsonValidationNote(
            'failed-to-parse-' + param.name,
            'Invalid parameter JSON for: ' + param.name.toUpperCase()
          );
          console.log(
            `Failed to parse: ${typeObj} ${
              parent ? 'w/ parent: ' + parent : ''
            } | ex: ${ex}`
          );
        }
      };

      try {
        getTypesRecursively(param.type, adaptedParam);

        // gets the display name for each parameter type and also combines certain types
        adaptedParam.selectableParameterTypes = this.selectableParameterTypes(
          adaptedParam.types
        );
        adaptedParam.selectedType =
          adaptedParam.selectableParameterTypes[0].type;
        adaptedParam.selectedDisplayType =
          adaptedParam.selectableParameterTypes[0].displayType;
        this.setInputs(adaptedParam.selectedType, adaptedParam);

        return adaptedParam;
      } catch (ex) {
        console.log(
          `Failed to set selected type and inputs for param: ${param} | ex: ${ex}`
        );
        return null;
      }
    },
    // Returns a list of parameter types the user can choose from
    // This will combine types such as 'enum', 'string', and 'path'
    selectableParameterTypes(typesList) {
      const baseTypes = typesList.map((a) => a.type);

      const stringInputs = [];
      const numberInputs = [];

      for (const type of baseTypes) {
        if (['null', 'str', 'path', 'enum'].includes(type)) {
          stringInputs.push(type);
        }
        if (['int', 'float'].includes(type)) {
          numberInputs.push(type);
        }
      }

      let parameterTypes = typesList.map((typeObj) => ({
        displayType: typeObj.type,
        ...typeObj,
      }));

      // combines all string types into a single type
      if (stringInputs.length > 1) {
        // gets all option groups for the enum type
        const optionGroups = parameterTypes
          .filter((a) => a.type === 'enum')
          .map((a) => a.optionGroups)
          .flat();

        parameterTypes = parameterTypes.filter(
          (a) => !stringInputs.includes(a.type)
        );

        parameterTypes.push({
          displayType: stringInputs.join(' / '),
          type: stringInputs.includes('enum')
            ? 'enum'
            : stringInputs.includes('str')
            ? 'str'
            : 'path',
          optionGroups: optionGroups,
        });
      }

      // combines all number types into a type of 'float'
      if (numberInputs.length > 1) {
        parameterTypes = parameterTypes.filter(
          (a) => !numberInputs.includes(a.type)
        );
        parameterTypes.push({
          displayType: numberInputs.join(' / '),
          type: 'float',
        });
      }

      for (const typeObj of parameterTypes) {
        if (typeObj.types) {
          typeObj.displayType = typeObj.type + '<';
          const getDisplayTypes = (types) => {
            for (const [index, element] of types.entries()) {
              typeObj.displayType += element.type;
              if (element.types) {
                typeObj.displayType += '<';
                getDisplayTypes(element.types);
                typeObj.displayType += '>';
              } else {
                if (index < types.length - 1) {
                  typeObj.displayType += ',';
                }
              }
            }
          };

          getDisplayTypes(typeObj.types);
          typeObj.displayType += '>';
        }
      }
      return parameterTypes;
    },
    // Sets the 'inputs' field on a param based on the 'type' parameter
    setInputs(
      type,
      param,
      parentType = [],
      resetInputs = true,
      allowMultiple = false,
      allowMultipleHorizontal = false,
      index = 0
    ) {
      try {
        let typeObj = type;
        if (typeof type === 'string') {
          // setInputs called from onChange event - find top level typeObj
          typeObj = param.types.find((t) => t.type === type);
        }
        if (resetInputs) {
          param.inputs = [[]];
          param.isList = false;
          param.isNDArray = false;
          param.allowMultiple = false;
        }
        if (typeObj.type === 'ndarray') {
          this.addJsonValidationNote(
            'ndarray-not-supported',
            'ndarrays are not supported yet.'
          );
        } else if (this.isSimpleType(typeObj.type)) {
          const getInput = (defaultValue) => {
            const input = {
              type: typeObj.type,
              inputType: this.getInputType(typeObj.type),
              value: defaultValue,
              allowMultiple: allowMultiple,
              allowMultipleHorizontal: allowMultipleHorizontal,
            };
            if (typeObj.type === 'enum') {
              // add options to input
              input.optionGroups = typeObj.optionGroups;
            }
            if (typeObj.type === 'bool') {
              // add options to input
              input.optionGroups = input.optionGroups ? input.optionGroups : [];
              input.optionGroups.push({
                name: 'Boolean',
                options: [true, false],
              });
            }
            return input;
          };
          if (
            (parentType[0] === 'list' || parentType[0] === 'variable_tuple') &&
            Array.isArray(param.defaultValue)
          ) {
            // lists will only contain a single type and therefor we must set all default values here
            for (const [rowIndex, rowValue] of param.defaultValue.entries()) {
              if (!param.inputs[rowIndex]) param.inputs.push([]);

              if (Array.isArray(param.defaultValue[rowIndex])) {
                // default value is a multi-dimensional array - add additional inputs to satisfy default values
                for (const [columnIndex, columnValue] of param.defaultValue[
                  rowIndex
                ].entries()) {
                  if (!param.inputs[rowIndex][columnIndex])
                    param.inputs[rowIndex].push([]);

                  if (
                    Array.isArray(param.defaultValue[rowIndex][columnIndex])
                  ) {
                    // default value is a multi-dimensional array - add additional inputs to satisfy default values
                    for (const [inputIndex, inputValue] of param.defaultValue[
                      rowIndex
                    ][columnIndex].entries()) {
                      console.log('input val: ', inputValue);
                      const inputToAdd = getInput(inputValue);
                      //if (!param.inputs[rowIndex][columnIndex][0])
                      //param.inputs[rowIndex][columnIndex].push([]);
                      param.inputs[rowIndex][columnIndex].push(inputToAdd);
                    }
                  } else {
                    const inputToAdd = getInput(columnValue);
                    param.inputs[rowIndex][columnIndex].push(inputToAdd);
                  }
                }
              } else {
                const inputToAdd = getInput(rowValue);
                param.inputs[rowIndex].push([inputToAdd]);
              }
            }
          } else {
            const columnIndex = param.inputs[0].length;
            let inputToAdd = null;
            if (typeObj.type === 'range') {
              inputToAdd = getInput(param.defaultValue);
              if (
                Array.isArray(param.defaultValue) &&
                param.defaultValue.length >= 2
              ) {
                inputToAdd.min = param.defaultValue[0];
                inputToAdd.max = param.defaultValue[1];
                if (param.defaultValue.length === 3) {
                  inputToAdd.step = param.defaultValue[2];
                }
              }
              param.inputs[0].push([inputToAdd]);
            } else if (parentType.includes('dict')) {
              // need to generate a new input for each row in the dictionary
              for (const [keyIndex, key] of Object.keys(
                param.defaultValue
              ).entries()) {
                const isDictionaryKey = index === 0;
                const value = isDictionaryKey ? key : param.defaultValue[key];
                let inputs = [];
                if (Array.isArray(value)) {
                  inputs = value.map((val) => getInput(val));
                } else {
                  inputs.push(getInput(value));
                }

                // Keys should be pushed as a new row, values should be pushed as a new column
                if (isDictionaryKey) {
                  if (!param.inputs[keyIndex]) {
                    param.inputs.push([inputs]);
                  } else {
                    param.inputs[keyIndex] = [inputs];
                  }
                } else {
                  param.inputs[keyIndex].push(inputs);
                }
              }
            } else {
              inputToAdd = getInput(
                Array.isArray(param.defaultValue)
                  ? Array.isArray(param.defaultValue[index])
                    ? param.defaultValue[index][columnIndex]
                    : param.defaultValue[index]
                  : param.defaultValue
              );
              param.inputs[0].push([inputToAdd]);
            }
          }
        } else {
          // list, variable_tuple, tuple, or dict
          let newAllowMultiple = false;
          let newAllowMultipleHorizontal = false;
          if (typeObj.type === 'list' || typeObj.type === 'variable_tuple') {
            typeObj.types = this.selectableParameterTypes(typeObj.types); // combines similar types into a single type (ex. int && float = float)

            if (parentType[0] === 'dict') {
              // allow multiple rows of inputs for dictionaries but not new horizontal inputs
              newAllowMultiple = true;
              newAllowMultipleHorizontal = false;
            } else if (resetInputs) {
              param.allowMultiple = true;
              newAllowMultiple = true;
            } else if (allowMultiple) {
              // list within a list
              newAllowMultipleHorizontal = true;
              newAllowMultiple = false;
            } else {
              // list within a list within a list
              newAllowMultiple = true;
              newAllowMultipleHorizontal = true;
            }
            parentType.unshift(typeObj.type);
            this.setInputs(
              typeObj.types[0],
              param,
              parentType,
              false,
              newAllowMultiple,
              newAllowMultipleHorizontal,
              index
            );
          } else {
            if (typeObj.type === 'dict' && resetInputs) {
              param.allowMultiple = true;
            }
            parentType.unshift(typeObj.type);
            for (const [i, innerType] of typeObj.types.entries()) {
              this.setInputs(
                innerType,
                param,
                parentType,
                false,
                newAllowMultiple,
                newAllowMultipleHorizontal,
                i
              );
            }
          }
        }
      } catch (ex) {
        this.addJsonValidationNote(
          'failed-to-create-input-' + param.name,
          "Invalid JSON. The 'type' field is likely configured incorrectly for parameter: " +
            param.name
        );
        console.log(`Failed to create input for type: ${type} | ex: ${ex}`);
      }
    },
    // Validates the param for all validators and returns whether the param is valid or not.
    // Sets the 'displayError' prop for all invalid validators.
    validateParam(param) {
      let valid = true;
      let values = [];
      const invalidTypeValidator = param.validators.find(
        (a) => a.type === 'invalid-type'
      );
      invalidTypeValidator.displayError = false;

      const setInvalid = (validator) => {
        validator.displayError = true;
        valid = false;
      };

      const setInvalidType = () => {
        invalidTypeValidator.displayError = true;
        valid = false;
      };

      const inputs = this.getInputsFromParam(param);
      for (const input of inputs) {
        try {
          if (input.type === 'range') {
            const rangeValues = this.getValue(input.type, input.value);
            if (rangeValues && isNaN(rangeValues[2])) {
              rangeValues.splice(2, 1); // don't include the optional 'step' value if it is NaN
            }
            values = values.concat(rangeValues);
          } else {
            values.push(this.getValue(input.type, input.value));
          }
        } catch (err) {
          // null is okay as long as the param is optional
          if (input?.value && input?.value !== 0) {
            setInvalidType();
          } else {
            values.push(input.value);
          }
        }
      }

      const columnLengths = param.inputs.map((row) => row.length);
      const inputLengths = param.inputs.flatMap((row) =>
        row.map((column) => column.length)
      );

      let maxPotentialDimensionCount = 0;
      if (param.allowMultiple) maxPotentialDimensionCount++;
      if (inputs.some((a) => a.allowMultipleHorizontal))
        maxPotentialDimensionCount++;
      if (inputs.some((a) => a.allowMultiple)) maxPotentialDimensionCount++;
      maxPotentialDimensionCount = Math.max(maxPotentialDimensionCount, 1);

      let actualDimensionCount = 0;
      if (param.inputs.length > 1) actualDimensionCount++;
      if (columnLengths.some((a) => a > 1)) actualDimensionCount++;
      if (inputLengths.some((a) => a > 1)) actualDimensionCount++;
      actualDimensionCount = Math.max(actualDimensionCount, 1);

      const isMultiDimensional = maxPotentialDimensionCount > 1;

      const dimensionSizes = {
        // 0, 1, 2 corresponds to a validators 'dimension' or 'value' property
        0: [param.inputs.length], // number of rows
        1: columnLengths, // number of columns
        2: inputLengths, // number of inputs
      };

      if (param.validators && param.validators.length > 0) {
        for (const validator of param.validators.filter(
          (a) => a.type !== 'invalid-type'
        )) {
          validator.displayError = false;

          if (validator.type === 'required') {
            if (values.some((v) => !HasValue(v))) {
              valid = false;
              validator.displayError = true;
            }
          }

          if (values.some((v) => HasValue(v))) {
            // only run validators if param has a value
            switch (validator.type) {
              case 'start':
                if (
                  (validator.inclusive &&
                    values.some((v) => v < validator.value)) ||
                  (!validator.inclusive &&
                    values.some((v) => v <= validator.value))
                ) {
                  validator.displayError = true;
                  valid = false;
                }
                break;
              case 'stop':
                if (
                  (validator.inclusive &&
                    values.some((v) => v > validator.value)) ||
                  (!validator.inclusive &&
                    values.some((v) => v >= validator.value))
                ) {
                  validator.displayError = true;
                  valid = false;
                }
                break;
              case 'step': {
                const start = param.validators.find((a) => a.type === 'start');
                const stop = param.validators.find((a) => a.type === 'stop');
                if (!start || !stop) {
                  this.addJsonValidationNote(
                    'start-stop-missing',
                    'Step validator requires start and stop validators to be present.'
                  );
                  break;
                }
                const validValues = [];
                let currentValue = start.inclusive
                  ? start.value
                  : start.value + validator.value;
                const stopValue = stop.inclusive
                  ? stop.value
                  : stop.value - validator.value;
                while (currentValue <= stopValue) {
                  if (
                    currentValue < stop.value ||
                    (currentValue === stop.value && stop.inclusive)
                  ) {
                    validValues.push(currentValue);
                  }
                  currentValue += validator.value;
                }
                if (values.some((v) => !validValues.includes(v))) {
                  setInvalid(validator);
                }
                break;
              }
              case 'available_values':
                if (
                  values.some(
                    (v) => !validator.values.map((a) => a.value).includes(v)
                  )
                ) {
                  setInvalid(validator);
                }
                break;
              case 'regex': {
                const regexPattern = new RegExp(validator.value);
                if (values.some((v) => !regexPattern.test(v))) {
                  setInvalid(validator);
                }
                break;
              }
              case 'dim_count_exact': {
                if (actualDimensionCount !== validator.value) {
                  setInvalid(validator);
                }
                break;
              }

              case 'dim_count_available': {
                if (!validator.values.includes(actualDimensionCount)) {
                  setInvalid(validator);
                }
                break;
              }

              case 'dim_count_range': {
                if (
                  actualDimensionCount < validator.values[0] ||
                  (validator.values.length > 1 &&
                    actualDimensionCount >= validator.values[1])
                ) {
                  setInvalid(validator);
                }
                break;
              }

              case 'size_exact': {
                if (
                  validator.dimension != null &&
                  dimensionSizes[validator.dimension].some(
                    (val) => val !== validator.value
                  )
                ) {
                  setInvalid(validator);
                  break;
                } else if (validator.dimension == null) {
                  // rows and columns must be same length
                  for (let i = 0; i < 2; i++) {
                    const isValid = dimensionSizes[i].every(
                      (val) => val === validator.value
                    );
                    if (i < maxPotentialDimensionCount && !isValid) {
                      setInvalid(validator);
                      break;
                    }
                  }
                }
                break;
              }

              case 'size_available': {
                if (
                  validator.dimension != null &&
                  dimensionSizes[validator.dimension].some(
                    (val) => !validator.values.includes(val)
                  )
                ) {
                  setInvalid(validator);
                  break;
                } else if (validator.dimension == null) {
                  // rows and columns must be same length
                  for (let i = 0; i < 2; i++) {
                    const isValid = dimensionSizes[i].every((val) =>
                      validator.values.includes(val)
                    );
                    if (i < maxPotentialDimensionCount && !isValid) {
                      setInvalid(validator);
                      break;
                    }
                  }
                }
                break;
              }
              case 'size_range': {
                if (
                  validator.dimension != null &&
                  (dimensionSizes[validator.dimension] < validator.values[0] ||
                    dimensionSizes[validator.dimension] >= validator.values[1])
                ) {
                  setInvalid(validator);
                  break;
                } else if (validator.dimension == null) {
                  // rows and columns must be same length
                  for (let i = 0; i < 2; i++) {
                    const isValid =
                      dimensionSizes[i].every(
                        (val) => val >= validator.values[0]
                      ) &&
                      dimensionSizes[i].every(
                        (val) => val < validator.values[1]
                      );
                    if (i < maxPotentialDimensionCount && !isValid) {
                      setInvalid(validator);
                      break;
                    }
                  }
                }
                break;
              }
            }
          }
        }
      }
      return valid;
    },
    // Converts a value to its respective type
    // Throws exception if it cannot convert the value to the type
    getValue(type, value) {
      switch (type) {
        case 'enum':
          return value.toString();
        case 'int': {
          const intVal = parseFloat(value);
          if (!Number.isSafeInteger(intVal)) throw 'not a number';
          return intVal;
        }
        case 'range': {
          if (!value || value.every((v) => v === null || v === undefined)) {
            return value;
          } else {
            const intVals = value.map((a) => parseFloat(a));
            for (const [i, val] of value.entries()) {
              if (i < 2 && !Number.isSafeInteger(parseFloat(val)))
                throw 'not a int';
              // i == 2 is the optional 'step' value, it can be null
              if (
                i === 2 &&
                !Number.isSafeInteger(parseFloat(val)) &&
                value[2] !== null &&
                value[2] !== undefined
              )
                throw 'not a int';
            }
            return intVals;
          }
        }
        case 'float': {
          const floatVal = parseFloat(value);
          if (isNaN(floatVal)) throw 'not a float';
          return floatVal;
        }
        case 'str':
        case 'path':
          return value.toString();
        case 'bool': {
          if (
            value == null ||
            !(
              value.toString().toUpperCase() === 'TRUE' ||
              value.toString().toUpperCase() === 'FALSE'
            )
          ) {
            throw 'not a bool';
          }
          const boolVal =
            value.toString().toUpperCase() === 'TRUE' ? true : false;
          return boolVal;
        }
        default:
          return value;
      }
    },
    // Convert params to a format that can be read by the pipeline backend
    convertToExportFormat() {
      const params = [];
      for (const param of this.adaptedAssayParams) {
        let formattedParam = {
          name: param.name,
        };
        const inputs = this.getInputsFromParam(param);

        let simplifiedValues = [];
        let hasValue = true;
        // Takes the Row[Column[inputs[]]] format and converts to a format similar to param.types
        const recursivelySimplifyInputs = (inputs, parent, isBase = false) => {
          if (!inputs.length) {
            // actual input
            parent.push(inputs.value);
          } else if (inputs.length === 1) {
            recursivelySimplifyInputs(inputs[0], parent);
          } else {
            const newValues = [];
            for (const input of inputs) {
              recursivelySimplifyInputs(input, newValues);
            }
            if (isBase) {
              simplifiedValues = newValues;
            } else {
              parent.push(newValues);
            }
          }
        };
        recursivelySimplifyInputs(param.inputs, simplifiedValues, true);

        if (!param.allowMultiple) {
          simplifiedValues = simplifiedValues[0];
        }

        // Generate type structure
        const typeObj = param.types.find((a) => a.type === param.selectedType); // might want to get types from selctableParameterTypes obj instead

        // creates the 'type' array for backend request
        const recursivelyCreateParam = (
          type,
          inputValue,
          paramToEdit,
          level
        ) => {
          if (type.types && type.types.length > 0) {
            const newParam = {
              category: type.type,
              type: [],
            };
            for (const [i, newTypeObj] of type.types.entries()) {
              recursivelyCreateParam(
                newTypeObj,
                inputValue ? inputValue[i] : inputValue,
                newParam,
                level + 1
              );
            }
            if (level === 0) {
              formattedParam = {
                ...formattedParam,
                ...newParam,
              };
            } else {
              paramToEdit.type.push(newParam);
            }
          } else {
            const newTypeObj = {
              category: 'single',
              type: type.type,
            };
            if (type.type === 'enum' && inputValue) {
              const input = inputs.find((a) => a.value === inputValue);
              if (input?.optionGroups) {
                const optionGroup = input.optionGroups.find((a) =>
                  a.options.includes(inputValue)
                );
                newTypeObj.module = optionGroup.module;
                newTypeObj.class = optionGroup.class;
              }
            }
            if (level === 0) {
              formattedParam = {
                ...formattedParam,
                ...newTypeObj,
              };
            } else {
              paramToEdit.type.push(newTypeObj);
            }
          }
        };
        recursivelyCreateParam(typeObj, simplifiedValues, formattedParam, 0);

        const setValue = (parentValues, value, index) => {
          if (index < 0) {
            simplifiedValues = value;
          } else {
            parentValues[index] = value;
          }
        };

        const recursivelyValidateAndSetExportFormat = (
          parentValues,
          values,
          type,
          index = -1
        ) => {
          if (type.types === null && values != null && values !== '') {
            // simple type
            try {
              const value = this.getValue(type.type, values);
              setValue(parentValues, value, index);
            } catch (ex) {
              console.error("Error getting value for type '" + type.type + "'");
            }
          } else if ((type.types === null && values == null) || values === '') {
            hasValue = false;
          }
          if (type.type === 'dict') {
            // takes [key, value] pairs and converts to a {key: value} object
            try {
              let dictObj = {};
              for (const keyValue of values) {
                if (
                  keyValue[0] != null &&
                  keyValue[0] !== '' &&
                  keyValue[1] != null &&
                  keyValue[1] !== ''
                ) {
                  let dictionaryValues = null;
                  if (type.types[1].type === 'list') {
                    const arrayVal = Array.isArray(keyValue[1])
                      ? keyValue[1]
                      : [keyValue[1]];
                    dictionaryValues = arrayVal.map((val, index) =>
                      this.getValue(
                        type.types[1].types[
                          type.types[1].types.length === 1 ? 0 : index
                        ].type,
                        val
                      )
                    );
                  } else {
                    dictionaryValues = this.getValue(
                      type.types[1].type,
                      keyValue[1]
                    );
                  }

                  dictObj = {
                    ...dictObj,
                    [this.getValue(
                      type.types[0].type,
                      keyValue[0]
                    )]: dictionaryValues,
                  };
                }
              }

              setValue(parentValues, dictObj, index);
              if (!Object.keys(dictObj).length) {
                hasValue = false;
              }
            } catch (ex) {
              console.error('Error getting value from dictionary.');
            }
          }
          if (
            typeof values === 'object' &&
            typeof type.types === 'object' &&
            type.types != null
          ) {
            const lastType = type.types[type.types.length - 1];
            for (let i = 0; i < values.length; i++) {
              recursivelyValidateAndSetExportFormat(
                values,
                values[i],
                i > type.types.length - 1 ? lastType : type.types[i], // types are 1:1 with values unless it's a list/variable tuple
                i
              );
            }
          }
        };
        recursivelyValidateAndSetExportFormat(
          simplifiedValues,
          simplifiedValues,
          typeObj,
          -1
        );

        formattedParam.value = simplifiedValues;
        if (hasValue) {
          params.push(formattedParam);
        }
      }
      return params;
    },
    async inputChanged(param) {
      this.validateParam(param);
      this.isValid = !this.adaptedAssayParams.some((parameter) =>
        parameter.validators.some((v) => v.displayError)
      );

      // this is what will be passed to the server when the user launches the assay
      this.formattedParams = this.convertToExportFormat();
      this.$emit('update:isValid', this.isValid);
      this.$emit('valuesChanged', this.formattedParams);
    },
    // ---- UTILITIES ----
    handleParameterTypeChanged(displayType, param) {
      const paramTypeObj = param.selectableParameterTypes.find(
        (a) => a.displayType === displayType
      );
      if (!paramTypeObj) {
        console.log('Cant find parameter type. This shouldnt happen');
        return;
      }
      param.selectedType = paramTypeObj.type;
      param.selectedDisplayType = paramTypeObj.displayType;
      this.setInputs(paramTypeObj.type, param);
      this.inputChanged(param);
    },
    updateRangeField(param, type, event, input) {
      let value = parseFloat(event.target.value);
      if (isNaN(value)) {
        value = null;
      }
      input[type] = value;

      input.value = [
        isNaN(input.min) ? null : input.min,
        isNaN(input.min) ? null : input.max,
        isNaN(input.min) ? null : input.step,
      ];
      this.inputChanged(param);
    },
    addColumn(param, rowIndex) {
      const inputToCopy = param.inputs[rowIndex][0][0];
      const newInput = {
        ...inputToCopy,
        value: null,
      };
      param.inputs[rowIndex].push([newInput]);
      this.inputChanged(param);
    },
    addRow(param, rowIndex = null, columnIndex = null) {
      if (rowIndex == null || columnIndex == null) {
        // add an entirely new row
        const newInputRow = param.inputs[0].map((a) =>
          a.map((b) => ({ ...b, value: null }))
        );
        param.inputs.push(newInputRow);
      } else {
        // add a new row to a specific field
        const input = param.inputs[rowIndex][columnIndex][0];
        const newInput = {
          ...input,
          value: null,
        };
        param.inputs[rowIndex][columnIndex].push(newInput);
      }
      this.inputChanged(param);
    },
    removeRow(param, rowIndex, columnIndex = null, inputIndex = null) {
      if (columnIndex == null || inputIndex == null) {
        // remove the entire row
        param.inputs.splice(rowIndex, 1);
      } else {
        if (param.inputs[rowIndex][columnIndex].length > 1) {
          // remove a particular input
          param.inputs[rowIndex][columnIndex].splice(inputIndex, 1);
        } else {
          // remove the entire column
          param.inputs[rowIndex].splice(columnIndex, 1);
        }
      }
      this.inputChanged(param);
    },
    // notes about invalid assay json
    addJsonValidationNote(key, message) {
      if (this.jsonValidationNotes.find((a) => a.key === key)) {
        return;
      } else {
        this.jsonValidationNotes.push({
          key,
          message,
        });
      }
    },
    // Gets the error message for the given validator type
    generateValidationMessage(validator) {
      switch (validator.type) {
        case 'invalid-type':
          return 'Invalid value type.';
        case 'required':
          return 'Value is required.';
        case 'start':
          return `Value must be ${
            validator.inclusive ? 'greater than or equal to ' : 'greater than '
          } ${validator.value}.`;
        case 'stop':
          return `Value must be ${
            validator.inclusive ? 'less than or equal to ' : 'less than '
          } ${validator.value}.`;
        case 'step':
          return `Value must be in increments of ${validator.value}.`;
        case 'available_values':
          return `Value must be one of the following: ${validator.values
            .map((a) => a.value)
            .join(', ')}.`;
        case 'regex':
          return `Value must match the following regex expression: ${validator.value}`;
        case 'dim_count_exact':
          return `Must have exactly ${validator.value} dimensions.`;
        case 'dim_count_available':
          return `Must have either ${validator.values.join()} dimensions.`;
        case 'dim_count_range': {
          if (validator.values.length > 1) {
            return `Must have between ${validator.values[0]} and ${validator
              .values[1] - 1} dimensions.`;
          } else {
            return `Must have at least ${validator.values[0]} items.`;
          }
        }
        case 'size_exact':
          return `Must have exactly ${validator.value} ${
            validator.dimension == null
              ? 'rows and columns'
              : validator.dimension === 0
              ? 'rows'
              : 'columns'
          }.`;
        case 'size_available':
          return `Must have either ${validator.values.join()} ${
            validator.dimension == null
              ? 'rows and columns'
              : validator.dimension === 0
              ? 'rows'
              : 'columns'
          }.`;
        case 'size_range': {
          return `Must have between ${validator.values[0]} and ${validator
            .values[1] - 1} ${
            validator.dimension == null
              ? 'rows and columns'
              : validator.dimension === 0
              ? 'rows'
              : 'columns'
          }.`;
        }
      }
    },
    getInputsFromParam(param) {
      const inputs = [];
      for (const inputRow of param.inputs) {
        for (const inputColumn of inputRow) {
          for (const input of inputColumn) {
            inputs.push(input);
          }
        }
      }
      return inputs;
    },
  },
};
</script>

<style scoped>
h4 {
  font-weight: bold;
}

.image-overview {
  max-height: 4rem;
  max-width: 10rem;
}
.red {
  color: red;
}
.grey {
  color: rgb(62, 62, 62);
}
</style>
<style>
label .label {
  margin-bottom: 0px;
}
label .label:not(:last-child) {
  margin-bottom: 0px;
}
</style>
