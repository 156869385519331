<template>
  <b-taginput
    :value="value"
    :data="filteredDomains"
    :open-on-focus="true"
    :field="displayedProperty"
    :group-field="groupDisplayedProperty"
    :placeholder="$t(placeholder)"
    :allow-new="allowNew"
    :allow-duplicates="false"
    :maxlength="maxLength"
    autocomplete
    @input="$emit('input', $event)"
    @typing="(val) => (searchString = val)"
    @add="searchString = ''"
  />
</template>

<script>
import { getWildcardRegexp } from '@/utils/string-utils.js';

export default {
  name: 'DomainTagInput',
  props: {
    value: Array,
    domains: Array,
    identifier: {
      type: String,
      default: 'id',
    },
    displayedProperty: {
      type: String,
      default: 'name',
    },
    groupDisplayedProperty: {
      type: String,
      default: '',
    },
    searchedProperty: {
      type: String | Array,
      default: 'name',
    },
    maxLength: {
      type: Number,
      default: null,
    },
    allowNew: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'search',
    },
  },
  data() {
    return {
      searchString: '',
    };
  },
  computed: {
    filteredDomains() {
      const selectedIds = this.value.map((v) => v[this.identifier]);
      const filtered = this.domains.filter(
        (user) => !selectedIds.includes(user[this.identifier])
      );
      if (this.searchString === '') {
        return filtered;
      }

      const regexp = getWildcardRegexp(this.searchString);

      return filtered.filter((user) => {
        if (Array.isArray(this.searchedProperty)) {
          return this.searchedProperty.some((property) =>
            regexp.test(user[property])
          );
        } else {
          return regexp.test(user[this.searchedProperty]);
        }
      });
    },
  },
};
</script>
