<template>
  <VForm @submit.prevent="onSubmit">
    <template #default="form">
      <CytomineModal :title="title" :active="active" @close="close">
        <p v-if="description" class="mb-5">
          {{ description }}
        </p>
        <IdxInput
          v-model="newName"
          :label="$t('new-name')"
          required
          name="name"
        />
        <template #footer>
          <IdxBtn @click="close">
            {{ $t('cancel') }}
          </IdxBtn>
          <IdxBtn type="submit" :disabled="!form.valid" color="primary">
            {{ $t('save') }}
          </IdxBtn>
        </template>
      </CytomineModal>
    </template>
  </VForm>
</template>

<script>
import CytomineModal from './CytomineModal.vue';

export default {
  name: 'RenameModal',
  components: { CytomineModal },
  extends: CytomineModal,
  props: {
    currentName: { type: String, default: '' },
    title: { type: String, default: '' },
    description: { type: String, default: '' },
  },
  data() {
    return {
      newName: '',
    };
  },
  watch: {
    active(active) {
      if (active) {
        this.newName = this.currentName;
      }
    },
  },
  created() {
    this.newName = this.currentName;
  },
  methods: {
    async onSubmit() {
      this.$emit('rename', this.newName);
      this.close();
    },
  },
};
</script>
