<template>
  <div class="list-projects-wrapper content-wrapper">
    <BLoading :is-full-page="false" :active="loading" />
    <div v-if="error" class="box error">
      <h2 class="mb-3">
        {{ $t('error') }}
      </h2>
      <p>{{ $t('unexpected-error-info-message') }}</p>
    </div>
    <div v-else-if="!loading" class="panel">
      <p class="panel-heading flex justify-between align-center">
        {{ $t('studies') }}
        <button
          v-if="currentUser.admin"
          class="button is-link"
          @click="creationModal = true"
        >
          {{ $t('study-new') }}
        </button>
      </p>
      <div class="panel-block">
        <div class="flex">
          <BInput
            v-model="searchString"
            :placeholder="$t('search')"
            class="search-projects mr-4"
            type="search"
            icon="search"
          />
          <button class="button" @click="toggleFilterDisplay()">
            <span class="icon">
              <i class="fas fa-filter" />
            </span>
            <span>
              {{ filtersOpened ? $t('filters-hide') : $t('filters-show') }}
            </span>
            <span v-if="nbActiveFilters" class="nb-active-filters">
              {{ nbActiveFilters }}
            </span>
          </button>
        </div>

        <BCollapse :open="filtersOpened">
          <div class="filters">
            <div class="columns">
              <div class="column filter">
                <div class="filter-label">
                  {{ $t('ontology') }}
                </div>
                <div class="filter-body">
                  <CytomineMultiselect
                    v-model="selectedOntologies"
                    :options="availableOntologies"
                    multiple
                    :all-placeholder="$t('all-ontologies')"
                    label="name"
                    track-by="id"
                  />
                </div>
              </div>

              <div class="column filter">
                <div class="filter-label">
                  {{ $t('my-role') }}
                </div>
                <div class="filter-body">
                  <CytomineMultiselect
                    v-model="selectedRoles"
                    :options="availableRoles"
                    :searchable="false"
                    multiple
                  />
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="column filter">
                <div class="filter-label">
                  {{ $t('members') }}
                </div>
                <div class="filter-body">
                  <CytomineSlider
                    v-model="boundsMembers"
                    :tooltip-direction="['left', 'right']"
                    :max="maxNbMembers"
                    :min-range="1"
                    :enable-cross="false"
                  />
                </div>
              </div>

              <div class="column filter">
                <div class="filter-label">
                  {{ $t('images') }}
                </div>
                <div class="filter-body">
                  <CytomineSlider
                    v-model="boundsImages"
                    :tooltip-direction="['left', 'right']"
                    :max="maxNbImages"
                    :min-range="1"
                    :enable-cross="false"
                  />
                </div>
              </div>

              <div class="column" />
            </div>
          </div>
        </BCollapse>

        <CytomineTable
          :collection="projectCollection"
          :current-page.sync="currentPage"
          :per-page.sync="perPage"
          :opened-detailed.sync="openedDetails"
          :sort.sync="sortField"
          :order.sync="sortOrder"
          :revision="revision"
          class="mt-4"
        >
          <template #default>
            <BTableColumn
              v-slot="props"
              field="currentUserRole"
              label=""
              centered
              width="1"
              sortable
            >
              <IconProjectMemberRole
                :is-manager="props.row.currentUserRoles.admin"
                :is-representative="props.row.currentUserRoles.representative"
              />
            </BTableColumn>

            <BTableColumn
              v-slot="props"
              :label="$t('name')"
              field="name"
              sortable
              width="250"
            >
              <RouterLink :to="`/project/${props.row.id}`">
                {{ props.row.name }}
                <i
                  v-if="props.row.isClosed"
                  class="fa fa-lock ml-1"
                  style="font-size:0.8em;"
                />
              </RouterLink>
            </BTableColumn>
            <!--
            <BTableColumn
              field="status"
              :label="$t('status')"
              width="250"
            >
              <img src="@/assets/status-bar/tissue.svg" id="tissue" width="50px"/>
              <img src="@/assets/status-bar/staining.svg" id="staining" width="50px"/>
              <img src="@/assets/status-bar/imaging.svg" id="imaging" width="50px"/>
              <img src="@/assets/status-bar/analysis.svg" id="analysis" width="50px"/>
            </BTableColumn>

            <BTableColumn
              field="membersCount"
              :label="$t('members')"
              centered
              sortable
              width="150"
            >
              {{ project.membersCount }}
            </BTableColumn>
            -->

            <BTableColumn
              v-slot="props"
              :label="$t('images')"
              field="numberOfImages"
              centered
              sortable
              width="150"
            >
              <RouterLink :to="`/project/${props.row.id}/images`">
                {{ props.row.numberOfImages }}
              </RouterLink>
            </BTableColumn>

            <BTableColumn
              v-slot="props"
              :label="$t('last-activity')"
              field="lastActivity"
              centered
              sortable
              width="180"
            >
              <span v-if="props.row.lastActivity">
                {{ Number(props.row.lastActivity) | date('ll') }}
              </span>
              <span v-else>
                {{ Number(props.row.created) | date('ll') }}
              </span>
            </BTableColumn>
          </template>

          <template #detail="{ row: project }">
            <ProjectDetails
              :project="project"
              :excluded-properties="excludedProperties"
              editable
              @update="updateProject()"
              @delete="deleteProject(project)"
            />
          </template>

          <template #empty>
            <div class="content has-text-grey has-text-centered">
              <p>{{ $t('study-none') }}</p>
            </div>
          </template>
        </CytomineTable>

        <div class="radius-12 p-4 bg-gray-1">
          <h2 class="mb-3">
            {{ $t('legend') }}
          </h2>
          <p class="mb-2">
            <IconProjectMemberRole /> : {{ $t('contributor-icon-label') }}
          </p>
          <p class="mb-2">
            <IconProjectMemberRole :is-manager="true" /> :
            {{ $t('manager-icon-label') }}
          </p>
          <p>
            <IconProjectMemberRole
              :is-manager="true"
              :is-representative="true"
            />
            : {{ $t('representative-icon-label') }}
          </p>
        </div>
      </div>
    </div>

    <AddProjectModal :active.sync="creationModal" :ontologies="ontologies" />
  </div>
</template>

<script>
import { ProjectCollection, OntologyCollection } from 'cytomine-client';

import CONSTANTS from '../../utils/constants.js';
import noteApi from '../../services/noteApi.js';
import ProjectDetails from './ProjectDetails.vue';
import AddProjectModal from './AddProjectModal.vue';
import {
  sync,
  syncBoundsFilter,
  syncMultiselectFilter,
} from '@/utils/store-helpers.js';

import CytomineTable from '@/components/utils/CytomineTable.vue';
import CytomineMultiselect from '@/components/form/CytomineMultiselect.vue';
import CytomineSlider from '@/components/form/CytomineSlider.vue';
import IconProjectMemberRole from '@/components/icons/IconProjectMemberRole.vue';

export class IdxProjectCollection extends ProjectCollection {
  async _doFetch() {
    const params = this.getParameters();
    const query = new URLSearchParams(params).toString();
    let url = `/napi/project`;
    url += query ? `?${query}` : '';

    return await noteApi.get(url);
  }
}

export default {
  name: 'ListProjects',
  components: {
    IconProjectMemberRole,
    CytomineTable,
    ProjectDetails,
    AddProjectModal,
    CytomineMultiselect,
    CytomineSlider,
  },
  data() {
    return {
      loading: true,
      error: false,

      projects: [],
      ontologies: [],

      contributorLabel: this.$t('contributor'),
      managerLabel: this.$t('manager'),

      creationModal: false,

      excludedProperties: [
        'name',
        'membersCount',
        'numberOfImages',
        'numberOfAnnotations',
        'numberOfJobAnnotations',
        'numberOfReviewedAnnotations',
        'lastActivity',
      ],

      maxNbMembers: 30,
      maxNbImages: 5000,

      revision: 0,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser.user;
    },

    searchString: sync('listProjects/searchString', { debounce: 500 }),
    filtersOpened: sync('listProjects/filtersOpened'),

    availableRoles() {
      return [this.contributorLabel, this.managerLabel];
    },

    availableOntologies() {
      return [
        {
          id: 'null',
          name: this.$t('no-ontology'),
        },
        ...this.ontologies,
      ];
    },

    selectedOntologies: syncMultiselectFilter(
      'listProjects',
      'selectedOntologies',
      'availableOntologies'
    ),
    selectedRoles: syncMultiselectFilter(
      'listProjects',
      'selectedRoles',
      'availableRoles'
    ),
    boundsMembers: syncBoundsFilter(
      'listProjects',
      'boundsMembers',
      'maxNbMembers'
    ),
    boundsImages: syncBoundsFilter(
      'listProjects',
      'boundsImages',
      'maxNbImages'
    ),

    nbActiveFilters() {
      return this.$store.getters['listProjects/nbActiveFilters'];
    },

    selectedOntologiesIds() {
      return this.selectedOntologies.map((ontology) => ontology.id);
    },

    boundsFilters() {
      return [
        {
          prop: 'numberOfImages',
          bounds: this.boundsImages,
        },
        {
          prop: 'membersCount',
          bounds: this.boundsMembers,
        },
      ];
    },

    projectCollection() {
      const collection = new IdxProjectCollection({
        withMembersCount: true,
        withLastActivity: true,
        withCurrentUserRoles: true,
      });

      if (
        this.selectedOntologiesIds.length > 0 &&
        this.selectedOntologiesIds.length < this.availableOntologies.length
      ) {
        collection['ontology'] = {
          in: this.selectedOntologiesIds.join(),
        };
      }
      if (this.selectedRoles.length > 0) {
        collection['currentUserRole'] = {
          in: this.selectedRoles.join().toLowerCase(),
        };
      }
      if (this.searchString) {
        collection['name'] = {
          ilike: encodeURIComponent(this.searchString),
        };
      }
      for (const { prop, bounds } of this.boundsFilters) {
        let maxValue = bounds[1];
        if (
          (prop == 'numberOfImages' && maxValue == this.maxNbImages) ||
          (prop == 'membersCount' && maxValue == this.maxNbMembers)
        ) {
          maxValue = 999999999;
        }
        collection[prop] = {
          gte: bounds[0],
          lte: maxValue,
        };
      }
      return collection;
    },

    currentPage: sync('listProjects/currentPage'),
    perPage: sync('listProjects/perPage'),
    sortField: sync('listProjects/sortField'),
    sortOrder: sync('listProjects/sortOrder'),
    openedDetails: sync('listProjects/openedDetails'),
  },
  watch: {
    revision() {
      this.fetchOntologies();
      this.fetchMaxFilters();
    },
  },
  async created() {
    try {
      await this.fetchOntologies();
    } catch (error) {
      console.log(error);
      this.error = true;
    }

    this.loading = false;
  },
  methods: {
    async fetchOntologies() {
      const ontologies = (await OntologyCollection.fetchAll({ light: true }))
        .array;
      ontologies.sort((a, b) => a.name.localeCompare(b.name));
      this.ontologies = ontologies;
    },
    toggleFilterDisplay() {
      this.filtersOpened = !this.filtersOpened;
    },
    updateProject() {
      this.revision++;
    },
    async deleteProject(projectToDelete) {
      try {
        await projectToDelete.delete();
        this.revision++;
        this.$notify({
          type: 'success',
          text: this.$t('project-deletion-notif-success', {
            projectName: projectToDelete.name,
          }),
        });
      } catch (error) {
        this.$notify({
          type: 'error',
          text: this.$t('project-deletion-notif-error', {
            projectName: projectToDelete.name,
          }),
        });
        return;
      }
    },
  },
};
</script>

<style>
.list-projects-wrapper td,
.list-projects-wrapper th {
  vertical-align: middle !important;
}
</style>
