<template>
  <b-message
    v-if="error"
    type="is-danger"
    has-icon
    icon-size="is-small"
    size="is-small"
  >
    <h2 class="mb-3">
      {{ $t('error') }}
    </h2>
    <p>{{ $t('unexpected-error-info-message') }}</p>
  </b-message>
  <table v-else class="table relative bg-transparent h-48">
    <b-loading :is-full-page="false" :active="loading" class="small" />
    <tbody v-if="!loading">
      <tr v-if="isPropDisplayed('name')">
        <td class="prop-label">
          {{ $t('name') }}
        </td>
        <td class="prop-content">
          {{ project.name }}
        </td>
      </tr>
      <tr v-if="isPropDisplayed('numberOfImages')">
        <td class="prop-label">
          {{ $t('images') }}
        </td>
        <td class="prop-content">
          <router-link :to="`/project/${project.id}/images`">
            {{ project.numberOfImages }}
          </router-link>
        </td>
      </tr>
      <tr v-if="isPropDisplayed('membersCount')">
        <td class="prop-label">
          {{ $t('members') }}
        </td>
        <td class="prop-content">
          {{ members.length }}
        </td>
      </tr>
      <tr v-if="isPropDisplayed('numberOfAnnotations')">
        <td class="prop-label">
          {{ $t('user-annotations') }}
        </td>
        <td class="prop-content">
          <router-link :to="`/project/${project.id}/annotations?type=user`">
            {{ project.numberOfAnnotations }}
          </router-link>
        </td>
      </tr>
      <tr v-if="isPropDisplayed('numberOfJobAnnotations')">
        <td class="prop-label">
          {{ $t('analysis-annotations') }}
        </td>
        <td class="prop-content">
          <router-link :to="`/project/${project.id}/annotations?type=algo`">
            {{ project.numberOfJobAnnotations }}
          </router-link>
        </td>
      </tr>
      <tr v-if="isPropDisplayed('numberOfReviewedAnnotations')">
        <td class="prop-label">
          {{ $t('reviewed-annotations') }}
        </td>
        <td class="prop-content">
          <router-link :to="`/project/${project.id}/annotations?type=reviewed`">
            {{ project.numberOfReviewedAnnotations }}
          </router-link>
        </td>
      </tr>
      <tr v-if="isPropDisplayed('description')">
        <td class="prop-label">
          {{ $t('description') }}
        </td>
        <td class="prop-content">
          <CytomineDescription :object="project" :can-edit="canEditProject" />
        </td>
      </tr>
      <!-- Tags hidden until scoped access per user per project -->
      <!-- <tr v-if="isPropDisplayed('tags')">
        <td class="prop-label">
          {{ $t('tags') }}
        </td>
        <td class="prop-content">
          <CytomineTags :object="project" :can-edit="canManageProject" />
        </td>
      </tr> -->
      <tr v-if="isPropDisplayed('properties')">
        <td class="prop-label">
          {{ $t('properties') }}
        </td>
        <td class="prop-content">
          <CytomineProperties
            :object="projectWithProperties"
            :can-edit="canEditProject && isSuperAdmin && isRepresentative"
          />
        </td>
      </tr>
      <tr v-if="isPropDisplayed('attachedFiles')">
        <td class="prop-label">
          {{ $t('attached-files') }}
        </td>
        <td class="prop-content">
          <AttachedFiles :object="project" :can-edit="canEditProject" />
        </td>
      </tr>
      <tr v-if="isPropDisplayed('ontology')">
        <td class="prop-label">
          {{ $t('ontology') }}
        </td>
        <td class="prop-content">
          <template v-if="project.ontology">
            <router-link
              v-if="currentUser.admin"
              :to="`/ontology/${project.ontology}`"
            >
              {{ project.ontologyName }}
            </router-link>
            <span v-else>{{ project.ontologyName }}</span>
          </template>
          <em v-else>{{ $t('no-ontology') }}</em>
        </td>
      </tr>
      <tr v-if="isPropDisplayed('created')">
        <td class="prop-label">
          {{ $t('created-on') }}
        </td>
        <td class="prop-content">
          {{ Number(project.created) | date('ll') }}
        </td>
      </tr>
      <tr v-if="isPropDisplayed('creator')">
        <td class="prop-label">
          {{ $t('creator') }}
        </td>
        <td class="prop-content">
          <ListUsernames :users="[creator]" :onlines="onlines" />
        </td>
      </tr>
      <tr v-if="isPropDisplayed('representatives')">
        <td class="prop-label">
          {{ $t('representatives') }} ({{ representatives.length }})
        </td>
        <td class="prop-content">
          <ListUsernames :users="representatives" :onlines="onlines" />
        </td>
      </tr>
      <tr v-if="isPropDisplayed('managers')">
        <td class="prop-label">
{{ $t('managers') }} ({{ managers.length }})
</td>
        <td class="prop-content">
          <ListUsernames :users="managers" :onlines="onlines" />
        </td>
      </tr>
      <tr v-if="isPropDisplayed('contributors')">
        <td class="prop-label">
          {{ $t('contributors') }} ({{ contributors.length }})
        </td>
        <td class="prop-content">
          <ListUsernames :users="contributors" :onlines="onlines" />
        </td>
      </tr>
      <tr v-if="!excludedProperties.includes('imagesPreview')">
        <td class="prop-label">
          {{ $t('images') }}
        </td>
        <td class="prop-content">
          <ListImagesPreview :project="project" />
        </td>
      </tr>
      <tr v-if="canManageProject">
        <td class="prop-label">
          {{ $t('actions') }}
        </td>
        <td class="prop-content">
          <ProjectActions
            :project="project"
            @update="$emit('update', $event)"
            @delete="$emit('delete')"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import noteApi from '../../services/noteApi.js';

import ProjectActions from './ProjectActions';
import ListImagesPreview from '@/components/image/ListImagesPreview';
import ListUsernames from '@/components/user/ListUsernames';
import CytomineDescription from '@/components/description/CytomineDescription';
import CytomineProperties from '@/components/property/CytomineProperties';
import CytomineTags from '@/components/tag/CytomineTags';
import AttachedFiles from '@/components/attached-file/AttachedFiles';

export default {
  name: 'ProjectDetails',
  components: {
    ListImagesPreview,
    ListUsernames,
    ProjectActions,
    CytomineDescription,
    CytomineProperties,
    CytomineTags,
    AttachedFiles,
  },
  props: {
    project: { type: Object, required: true },
    excludedProperties: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      error: false,

      creator: null,
      managers: [],
      members: [],
      onlines: [],
      representatives: [],

      projectProperties: {},
    };
  },
  computed: {
    /** @returns {CytoUser} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    canManageProject() {
      return (
        this.editable && this.$store.getters['currentProject/canManageProject']
      );
    },
    canEditProject() {
      return (
        this.editable && this.$store.getters['currentProject/canEditProject']
      );
    },
    isSuperAdmin() {
      return this.currentUser.adminByNow;
    },
    isRepresentative() {
      return this.$store.getters['currentProject/isRepresentative'];
    },
    managersIds() {
      return this.managers.map((manager) => manager.id);
    },
    contributors() {
      return this.members.filter(
        (member) => !this.managersIds.includes(member.id)
      );
    },
    projectWithProperties() {
      return {
        ...this.$store.state.currentProject.project,
        properties: this.projectProperties,
      };
    },
  },
  async created() {
    try {
      await Promise.all([
        this.fetchCreator(),
        this.fetchManagers(),
        this.fetchRepresentatives(),
        this.fetchMembers(),
        this.fetchOnlines(),
      ]);
    } catch (error) {
      console.log(error);
      this.error = true;
    }
    this.loading = false;
  },
  async mounted() {
    try {
      const response = await noteApi.get(
        `/api/domain/${this.project.class}/${this.project.id}/property.json?max=0&offset=0`
      );
      this.projectProperties = response.collection;
    } catch (error) {
      console.log(error);
      this.error = true;
    }
  },
  methods: {
    async fetchCreator() {
      this.creator = await this.project.fetchCreator();
    },
    async fetchManagers() {
      this.managers = (await this.project.fetchAdministrators()).array;
    },
    async fetchRepresentatives() {
      this.representatives = (await this.project.fetchRepresentatives()).array;
    },
    async fetchMembers() {
      this.members = (await this.project.fetchUsers()).array;
    },
    async fetchOnlines() {
      this.onlines = await this.project.fetchConnectedUsers();
    },

    isPropDisplayed(prop) {
      return !this.excludedProperties.includes(prop);
    },

    deleteProject() {
      this.$buefy.dialog.confirm({
        title: this.$t('project-delete'),
        message: this.$t('project-delete-confirmation-message', {
          projectName: this.project.name,
        }),
        type: 'is-danger',
        confirmText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        onConfirm: () => this.$emit('delete'),
      });
    },
  },
};
</script>

<style scoped>
td.prop-label {
  white-space: nowrap;
  font-weight: 600;
}

td.prop-content {
  width: 100%;
}
</style>
