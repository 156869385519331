<template>
  <nav :aria-label="$t('main-nav')" class="navbar is-light size-12 weight-6">
    <div class="navbar-brand">
      <RouterLink to="/" exact class="navbar-item">
        <img id="logo" src="@/assets/logo.svg" alt="imageDx" />
      </RouterLink>
      <button
        :class="['btn-plain', { 'is-active': openedTopMenu }]"
        class="navbar-burger"
        @click="openedTopMenu = !openedTopMenu"
      >
        <span /> <span /> <span />
        <span class="visually-hidden">{{ $t('menu-toggle') }}</span>
      </button>
    </div>
    <div :class="{ 'is-active': openedTopMenu }" class="navbar-menu">
      <div class="navbar-start">
        <NavbarDropdown
          v-if="nbActiveProjects > 0"
          :title="$t('workspace')"
          icon="fa-folder-open"
        >
          <NavigationTree />
        </NavbarDropdown>
        <RouterLink
          to="/storage"
          class="navbar-item"
        >
          <i class="fas fa-download pr-2" />
          {{ $t('uploads') }}
        </RouterLink>
        <RouterLink
          v-if="currentUser.admin"
          to="/ontology"
          class="navbar-item"
        >
          <i class="fas fa-hashtag pr-2" />
          {{ $t('ontologies') }}
        </RouterLink>
        <RouterLink v-if="currentUser.adminByNow" to="/software" class="navbar-item"> 
          <i class="fas fa-code pr-2" />
          {{ $t('digital-assays') }}
        </RouterLink>
        <RouterLink
          v-if="currentUser.adminByNow"
          to="/admin"
          class="navbar-item"
        >
          <i class="fas fa-wrench pr-2" />
          {{ $t('admin-menu') }}
        </RouterLink>
      </div>

      <div class="navbar-end">
        <CytomineSearcher />

        <NavbarDropdown
          :icon="
            currentUser.adminByNow
              ? 'fa-star'
              : currentUser.isSwitched
                ? 'fa-user-ninja'
                : 'fa-user'
          "
          :title="currentUserFullInfo"
          :link-classes="{ 'has-text-dark-primary': currentUser.isSwitched }"
          :tag="
            currentUser.adminByNow
              ? { type: 'is-danger', text: $t('admin') }
              : null
          "
          :list-pathes="['/account', '/activity']"
        >
          <RouterLink to="/account" class="navbar-item">
            <span class="icon"><i class="fas fa-user fa-xs" /></i></span>
            {{ $t('account') }}
          </RouterLink>
          <RouterLink to="/activity" class="navbar-item">
            <span class="icon"><i class="fas fa-history fa-xs" /></i></span>
            {{ $t('activity-history') }}
          </RouterLink>
          <!-- <template v-if="currentUser.adminByNow">
            <button
              v-if="!currentUser.adminByNow"
              class="navbar-item btn-plain"
              @click="openAdminSession"
            >
              <span class="icon"><i class="fas fa-star fa-xs" /></i></span>
              {{ $t('open-admin-session') }}
            </button>
            <button
              v-else
              class="navbar-item btn-plain"
              @click="closeAdminSession"
            >
              <span class="icon"><i class="far fa-star fa-xs" /></i></span>
              {{ $t('close-admin-session') }}
            </button>
          </template> -->
          <template v-if="currentUser.isSwitched">
            <button
              class="navbar-item btn-plain has-text-dark-primary"
              @click="stopSwitchUser"
            >
              <span class="icon"><i class="fas fa-exchange-alt fa-xs" /></i></span>
              {{
                $t('switch-back-to-user', { username: currentUser.realUser })
              }}
            </button>
          </template>

          <button class="navbar-item btn-plain" @click="openHotkeysModal">
            <span class="icon"><i class="far fa-keyboard fa-xs" /></i></span>
            {{ $t('shortcuts') }}
          </button>

          <button class="navbar-item btn-plain" @click="logout">
            <span class="icon"><i class="fas fa-power-off fa-xs" /></i></span>
            {{ $t('logout') }}
          </button>

          <a
            class="navbar-item btn-plain"
            href="https://revealbio.atlassian.net/servicedesk/customer/portals"
            target="_blank"
          >
            <span class="icon"><i class="fas fa-question fa-xs" /></span>
            {{ $t('imagedx-support') }}
          </a>
        </NavbarDropdown>
      </div>
    </div>
  </nav>
</template>

<script>
import { Cytomine } from 'cytomine-client';
import NavbarDropdown from './NavbarDropdown.vue';
import NavigationTree from './NavigationTree.vue';
import HotkeysModal from './HotkeysModal.vue';
import noteApi from '@/services/noteApi';
import { changeLanguageMixin } from '@/lang.js';

import CytomineSearcher from '@/components/search/CytomineSearcher.vue';
import { fullName } from '@/utils/user-utils.js';

export default {
  name: 'CytomineNavbar',
  components: {
    NavbarDropdown,
    NavigationTree,
    CytomineSearcher,
  },
  mixins: [changeLanguageMixin],
  data() {
    return {
      openedTopMenu: false,
      hotkeysModal: false,
      aboutModal: false,
    };
  },
  computed: {
    /** @returns {object} */
    currentUser() {
      return this.$store.state.currentUser.user;
    },
    /** @returns {string} */
    currentUserFullInfo() {
      return fullName(this.currentUser);
    },
    /** @returns {number} */
    nbActiveProjects() {
      return Object.keys(this.$store.state.projects).length;
    },
  },
  watch: {
    $route() {
      this.openedTopMenu = false;
    },
  },
  methods: {
    // required to use programmatic modal for correct display in IE11
    openHotkeysModal() {
      this.$buefy.modal.open({
        parent: this,
        component: HotkeysModal,
        hasModalCard: true,
      });
    },

    async openAdminSession() {
      try {
        await this.$store.dispatch('currentUser/openAdminSession');
        this.$router.push('/admin');
      } catch (error) {
        console.log(error);
      }
    },
    async closeAdminSession() {
      try {
        await this.$store.dispatch('currentUser/closeAdminSession');
        this.$router.push('/');
      } catch (error) {
        console.log(error);
      }
    },

    async stopSwitchUser() {
      try {
        await Cytomine.instance.stopSwitchUser();
        await this.$store.dispatch('currentUser/fetchUser');
        this.$router.push('/');
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-failed-to-switch-back-as-real-user'),
        });
      }
    },

    async logout() {
      try {
        await noteApi.get('/logout');
        this.$store.dispatch('logout');
        this.changeLanguage();
        this.$router.push('/');
      } catch (error) {
        console.log(error);
        this.$notify({
          type: 'error',
          text: this.$t('notif-error-logout'),
        });
      }
    },
  },
};
</script>

<style lang="scss">
.navbar {
  z-index: 500 !important;
}

.navbar-item.btn-plain {
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.navbar-item.btn-plain:hover,
.navbar-item.btn-plain.is-hovered {
  background-color: #fafafa;
  color: #3273dc;
}

/* Different hover colors for larger windows screens */
@media screen and (min-width: 1024px) {
  .navbar-item.btn-plain:hover,
  .navbar-item.btn-plain.is-hovered {
    background-color: whitesmoke;
    color: #0a0a0a;
  }
}

@media screen and (min-width: 1400px) {
  #app .navbar {
    font-size: 1rem;
  }
}
</style>
